// Enum for validation input for course progression
enum Progression {
    A = 'A',
    B = 'B',
    C = 'C'
}

// Interface for course
interface CourseInfo {
    code: string;
    name: string;
    progression: Progression;
    syllabus: string;
}